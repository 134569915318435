import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link, Navigate, useNavigate } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import moment from 'moment';
import CountUp from 'react-countup';
import { VendersContext } from '../../context/Store';

const Users = (props) => {
  let { isLang, setIsLang } = useContext(VendersContext);


  const [products, setProducts] = useState([]);

  async function getUsers(page) {
    try {
      const headers = {
        token: localStorage.getItem('userToken')
      };

      const response = await axios.get(`https://api.alaran.de/api/dashboard/users?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.users);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    }
  }

  // users search
  async function searchUsers(e, page) {
    if (e.target.value) {

      let { data } = await axios.get(`https://api.alaran.de/api/dashboard/tables_search/users/${e.target.value}?page=${page}`, {
        headers: {
          token: localStorage.getItem('userToken')


        }
      })
      setProducts(data.data.users);
      //console.log(data);
    }
    else {
      getUsers();
    }

  }

  useEffect(() => {


    getUsers(1);
  }, []);




  let emptyProduct = {
    name: ''

  };


  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nameTouched, setNameTouched] = useState(false);
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);
  const [isAddingBrand, setIsAddingBrand] = useState(false);



  const openNew = () => {
    setIsAddingBrand(true);
    setProduct(emptyProduct);
    setProductDialog(true);
  };


  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };


  function onPageChange(event) {
    getUsers(event.page + 1);
  }


  const onSave = async (product) => {
    try {
      const response = await axios.post(`https://api.alaran.de/api/dashboard/stores/update`, product, {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('userToken')
        }
      });
      const data = response.data;
      // handle response data
      getUsers();
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Store updated successfully', life: 3000 });
    } catch (error) {
      // handle error
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update Store', life: 3000 });
    }
  };




  //delete one product
  const hideDeleteBrandDialoge = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      // Create a payload object with the product ID
      const payload = {
        user_id: product.id
      };

      // Send a DELETE request to the API endpoint with the payload in the request body and the Authorization header with the token value
      await axios.delete('https://api.alaran.de/api/dashboard/users/user_delete', {
        headers: {
          token: localStorage.getItem('userToken')
        },
        data: payload
      });

      // Update the products list state to remove the product that was deleted
      const updatedProducts = products.filter((val) => val.id !== product.id);
      setProducts(updatedProducts);

      // Reset the product state
      setProduct(emptyProduct);

      // Hide the delete product dialog
      setDeleteProductDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
    } catch (error) {
      //console.log(error.response);
      // Show an error toast message
      setDeleteProductDialog(false);

      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete user', life: 4000 });
    }
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  //delete selected product

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    try {
      // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
      const deletePromises = selectedProducts.map((product) => {
        return axios.delete(`https://api.alaran.de/api/dashboard/stores/delete/${product.id}`, {
          headers: {
            token: localStorage.getItem('userToken')
          }
        });
      });
      await Promise.all(deletePromises);

      // Update the products list state to remove the deleted products
      const updatedProducts = products.filter((val) => !selectedProducts.includes(val));
      setProducts(updatedProducts);

      // Reset the selected products state and hide the delete products dialog
      setSelectedProducts(null);
      setDeleteProductsDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Brands Deleted', life: 3000 });
    } catch (error) {
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete brands', life: 3000 });
    }
  };


  const addedDateBodyTemplate = (rowData) => {
    const formattedDate = moment(rowData.created_at).format('DD-MM-YYYY');
    return <span>{formattedDate}</span>;
  };

  const pointsBodyTemplate = (rowData) => {
    return <span>{rowData.all_points}</span>;

  };



  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);

    if (name === 'name') {
      setNameTouched(true);
    }
  };

  const navigate = useNavigate();

  const viewUserDetails = (product) => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/view-user/${product.id}`);
  };


  const blockUser = async (rowData) => {
    try {
      let newStatus;
      if (rowData.is_active === 0) {
        // If the user is disabled, set the new status to active (unblock the user)
        alert('User Unblocked Successfully');
        newStatus = 1;
      } else {
        // If the user is active, set the new status to disabled (block the user)
        alert('User Blocked Successfully');

        newStatus = 0;
      }
      getUsers()
      // Create a payload object with the user ID and the updated status
      const payload = {
        user_id: rowData.id,
        active: newStatus
      };

      // Send a POST request to the API endpoint to update the user status
      await axios.post('https://api.alaran.de/api/dashboard/users/user_status', payload, {
        headers: {
          token: localStorage.getItem('userToken')
        }
      });
      getUsers()
      // Update the user row with the updated status

      const updatedUsers = [...products];
      const updatedUserIndex = updatedUsers.findIndex(user => user.id === rowData.id);
      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex].active = newStatus;
        //console.log(newStatus);
        setProducts(updatedUsers);

        // Store the updated user status in local storage
        localStorage.setItem(`user_${rowData.id}_status`, newStatus);
      }
    } catch (error) {
      //console.log(error.response);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex gap-4'>
          <Button icon="pi pi-eye" rounded outlined severity="info" onClick={() => viewUserDetails(rowData)} />
          <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
          <Button icon="pi pi-ban" rounded outlined className={`mr-2 text-light ${rowData.is_active === 0 ? 'bg-danger' : 'bg-primary'}`} onClick={() => blockUser(rowData)} />        </div>
      </React.Fragment>
    );
  };
  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className='d-flex align-items-center '>

        <h3 className='p-2 '> {isLang === "en" ? "Total Products:" : "إجمالي المنتجات:"}</h3>
        <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h3" />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText onChange={searchUsers} type="search" onInput={(e) => searchUsers(e.target.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />
      </span>
    </div>
  );
  // const leftToolbarTemplate = () => {
  //   return (
  //     <div className="d-flex flex-wrap gap-3">
  //       <Button label="Add User" icon="pi pi-plus" severity="success" onClick={openNew} />        <Button
  //         label="Delete"
  //         icon="pi pi-trash"
  //         severity="danger"
  //         onClick={confirmDeleteSelected}
  //         className={selectedProducts && selectedProducts.length ? '' : 'p-disabled'}
  //       />
  //     </div>
  //   );
  // };

  const saveBrand = async (brand) => {
    try {
      const response = await fetch('https://api.alaran.de/api/dashboard/stores/create', {
        method: 'POST',
        headers: {
          token: localStorage.getItem('userToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(brand),
      });
      const data = await response.json();
      // handle response data
      getUsers();
      setProducts([...products, data]); // add new store to products state
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Store added successfully', life: 3000 });
    } catch (error) {
      // handle error
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add Store', life: 3000 });
    }
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const handleSaveClick = () => {
    if (isAddingBrand) {
      saveBrand(product);
    } else {
      onSave(product);
    }
    setIsAddingBrand(false);
    setProduct(emptyProduct);
    setProductDialog(false);
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );

  return (

    <div>
      <Toast ref={toast} />
      <div className="card ">
        <DataTable
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" className="px-3" exportable={true}></Column>
          {/* <Column field="id" header="ID" style={{ minWidth: '12rem' }} ></Column> */}
          <Column field="name" header={isLang === "en" ? "User Name" : "اسم العميل"} style={{ minWidth: '16rem' }}></Column>
          <Column
            field="addedDate"
            header={isLang === "en" ? "Added Date" : "تاريخ الإضافة"}
            body={addedDateBodyTemplate}
            className="px-3"
            style={{ minWidth: '10rem' }}
          ></Column>

          <Column field="points" header={isLang === "en" ? "points " : "النقاط"}
            body={pointsBodyTemplate} className="px-3" style={{ minWidth: '10rem' }} />

          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <Paginator ref={dtt.current} totalRecords={totalRecords} rows={10} onPageChange={onPageChange}></Paginator>
      </div>



      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header= {isLang === "en" ? "Confirm" : "تأكيد"} modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
        <div className="confirmation-content ">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && (
            <span>
              {isLang === "en" ? " Are you sure you want to delete" : "هل أنت متأكد أنك تريد حذف "} <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteBrandDialoge}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && <span>Are you sure you want to delete the selected products?</span>}
        </div>
      </Dialog>
    </div>


  );
}

export default (Users);