import React, { useState, useEffect, useContext } from 'react';
import { VendersContext } from '../../context/Store';

const Points = () => {
    const [productCount, setProductCount] = useState('');
    const [pointsValue, setPointsValue] = useState('');
    let { isLang, setIsLang } = useContext(VendersContext);

    useEffect(() => {
        fetchPointsValue();
    }, []);

    const fetchPointsValue = async () => {
        try {
            const response = await fetch('https://api.alaran.de/api/dashboard/setting/get_points_value', {
                headers: {
                    token: localStorage.getItem('userToken'), // Add the token header
                    'Content-Type': 'application/json'
                }
            }); const data = await response.json();

            if (response.ok) {
                setPointsValue(data.data);
            } else {
                //console.log('Failed to fetch points value.');
            }
        } catch (error) {
            console.error('Error fetching points value:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'productCount') {
            setProductCount(value);
        } else if (name === 'pointsValue') {
            setPointsValue(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch('https://api.alaran.de/api/dashboard/setting/update_points_value', {
                method: 'POST',
                headers: {
                    token: localStorage.getItem('userToken'), // Add the token header
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ points: parseInt(pointsValue) })
            });

            if (response.ok) {
                //console.log('Points value updated successfully!');
                alert('Points value updated successfully!')
            } else {
                //console.log('Failed to update points value.');
                alert('Failed to update points value.')
            }
        } catch (error) {
            console.error('Error updating points value:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>

            <h5 className='pt-2 d-flex justify-content-center'> {isLang === "en" ? "This value represents the worth of a single approved product." : "هذه القيمة تمثل عدد النقاط لمنتج واحد مقبول."}</h5>

            <div>
                <label htmlFor="pointsValue" className='h6 mt-3'> {isLang === "en" ? "Points Value:" : "قيمة النقاط"}</label>
                <input
                    className="form-control form-control-lg mb-3"
                    type="number"
                    id="pointsValue"
                    name="pointsValue"
                    value={pointsValue}
                    onChange={handleInputChange}
                />
            </div>
            <button type="submit" class="btn btn-primary"> {isLang === "en" ? "Save" : "حفظ"}</button>
        </form>
    );
};

export default Points;