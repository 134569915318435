import React, { useState, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { VendersContext } from '../../context/Store';

const CompanyInfo = () => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [settings, setSettings] = useState({
    app_email: '',
    app_name_de: '',
    app_name_en: '',
    app_name_ar: '',
    app_phone: '',
    playstore: '',
    appstore: '',
    facebook_url: '',
    linkdin_url: '',
    instagram_url: '',
    twitter_url: '',
    whatsapp_url: '',
    reviews_enabled: ''
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch('https://api.alaran.de/api/dashboard/setting/get_setting_values', {
        headers: {
          token: localStorage.getItem('userToken'),
        },
      });
      const data = await response.json();
      const fetchedSettings = {
        ...data.data,
      };
      setSettings(data.data);
      //console.log(data.data.reviews_enabled);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };
  const handleReviewsEnabledChange = (e) => {
    const value = e.target.checked ? "1" : "0";
    setSettings((prevSettings) => ({
      ...prevSettings,
      reviews_enabled: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update the value before sending the request
      const updatedSettings = {
        ...settings,
      };

      const response = await fetch('https://api.alaran.de/api/dashboard/setting/update_setting_info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('userToken'),
        },
        body: JSON.stringify(updatedSettings),
      });

      if (response.ok) {
        //console.log('Settings updated successfully');
        alert('Updated successfully!');
      } else {
        console.error('Failed to update settings');
        alert('Failed to Update');
      }

      fetchSettings();
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} >
        <h5>{isLang === "en" ? "General Informations:" : "المعلومات العامة"} </h5>
        <TabView>
          <TabPanel header={isLang === "en" ? "English" : "اللغة الانجليزية"}>


            <label className='h6 mt-3 info-label' htmlFor="app_name_de">{isLang === "en" ? "Name" : "الاسم"} </label>
            <input
              className="form-control"
              type="text"
              id="app_name_de"
              name="app_name_de"
              value={settings.app_name_de}
              onChange={handleInputChange}
            />
          </TabPanel>
          <TabPanel header={isLang === "en" ? "Germany" : "اللغة الالمانية"}>


            <label className='h6 mt-3 info-label' htmlFor="app_name_en">{isLang === "en" ? "Name" : "الاسم"} </label>
            <input
              className="form-control"

              type="text"
              id="app_name_en"
              name="app_name_en"
              value={settings.app_name_en}
              onChange={handleInputChange}
            />
          </TabPanel>
          <TabPanel header={isLang === "en" ? "Arabic" : "اللغة العربية"}>

            <label className='h6 mt-3 info-label' htmlFor="app_name_ar">{isLang === "en" ? "Name" : "الاسم"}</label>
            <input className="form-control"

              type="text"
              id="app_name_ar"
              name="app_name_ar"
              value={settings.app_name_ar}
              onChange={handleInputChange}
            />
          </TabPanel>

        </TabView>
        <div className='mx-3'>

          <label className='h6 mt-3 info-label' htmlFor="app_email">{isLang === "en" ? "Email" : "الايميل"}</label>
          <input
            className="form-control"
            type="email"
            id="app_email"
            name="app_email"
            value={settings.app_email}
            onChange={handleInputChange}
          />

          <label className='h6 mt-3 info-label' htmlFor="app_phone">{isLang === "en" ? "Phone" : "الهاتف"}</label>
          <input className="form-control"

            type="tel"
            id="app_phone"
            name="app_phone"
            value={settings.app_phone}
            onChange={handleInputChange}
          />
          <h5 className='pt-4'> {isLang === "en" ? "Social Media URLS:" : "روابط التواصل الاجتماعي:"}</h5>
          <div className='mb-3'>

            <label className='h6 mt-3 info-label' htmlFor="facebook_url"> {isLang === "en" ? "Facebook" : "الفيسبوك"} </label>
            <input className="form-control"

              id="facebook_url"
              name="facebook_url"
              value={settings.facebook_url}
              onChange={handleInputChange}
            />


            <label className='h6 mt-3 info-label' htmlFor="linkdin_url">{isLang === "en" ? "LinkedIn" : "لينكدان"} </label>
            <input className="form-control"

              id="linkdin_url"
              name="linkdin_url"
              value={settings.linkdin_url}
              onChange={handleInputChange}
            />

            <label className='h6 mt-3 info-label' htmlFor="instagram_url">{isLang === "en" ? "Instagram" : "انستجرام"}  </label>
            <input className="form-control"

              id="instagram_url"
              name="instagram_url"
              value={settings.instagram_url}
              onChange={handleInputChange}
            />


            <label className='h6 mt-3 info-label' htmlFor="twitter_url">{isLang === "en" ? "Twitter" : "تويتر"}  </label>
            <input className="form-control"

              id="twitter_url"
              name="twitter_url"
              value={settings.twitter_url}
              onChange={handleInputChange}
            />

            <label className='h6 mt-3 info-label' htmlFor="whatsapp_url">{isLang === "en" ? "WhatsApp" : "واتساب"}  </label>
            <input className="form-control"

              id="whatsapp_url"
              name="whatsapp_url"
              value={settings.whatsapp_url}
              onChange={handleInputChange}
            />
          </div>

          <h5 className='pt-2'>{isLang === "en" ? "Other Settings:" : "اعدادات اخرى:"} </h5>
          <div className="card w-50 mb-3 p-2 justify-content-between mt-3 d-flex flex-row align-items-center">
            <h6 className="ml-2">
              {isLang === "en" ? " Allow users to add comments" : " السماح للعملاء بالتعليق على المنتجات"}
            </h6>
            <Checkbox
              className='border-3 checkbox-allow'
              value={settings?.reviews_enabled}
              checked={settings.reviews_enabled === "1"}
              onChange={handleReviewsEnabledChange}
            />
          </div>

          <button type="submit" className="btn btn-primary static-btn " > {isLang === "en" ? "Save" : "حفظ"} </button>
        </div>


      </form>

    </>
  );
};

export default CompanyInfo;