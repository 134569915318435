import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import axios from 'axios';
import { VendersContext } from '../../context/Store';
const AdminTable = () => {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('langChange') || 'en');
  const [currentPage, setCurrentPage] = useState(1);
  const [admins, setAdmins] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [displayEditDialog, setDisplayEditDialog] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [adminData, setAdminData] = useState({}); // Initialize adminData with an empty object
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState('');
  let { isLang, setIsLang } = useContext(VendersContext);


  const [newAdminData, setNewAdminData] = useState({
    name: '',
    email: '',
    role_id: [], // Initialize as an empty array
    password: '',
  });
  const [updatedAdminData, setUpdatedAdminData] = useState({
    name: '',
    email: '',
    role_id: '', // Initialize as an empty array
    password: '',
  });

  async function getRolesData() {
    try {
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: localStorage.getItem('userToken'),
        lang: langChange || 'en' // set default language to 'en' if langChange is not set
      };

      // add support for additional languages
      if (langChange === 'de') {
        headers.lang = 'de';
      } else if (langChange === 'ar') {
        headers.lang = 'ar';
      }

      const response = await axios.get('https://api.alaran.de/api/dashboard/roles/all_roles', { headers });
      const data = response.data;

      setRoles(data.data);

      //console.log(data.data);
    } catch (error) {
      console.error(error);
    }
  }


  const fetchAdmins = async (page) => {
    try {
      const token = localStorage.getItem('userToken');
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: token,
        lang: langChange,
      };

      const response = await axios.get(
        `https://api.alaran.de/api/dashboard/admins?page=${page}`,
        { headers }
      );

      setAdmins(response.data.data.admins);
      setTotalPages(response.data.data.total_pages);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const fetchAdminById = async (adminId) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`https://api.alaran.de/api/dashboard/admins/${adminId}`, {
        headers: {
          token: token,
        },
      });
      const admin = response.data.data;
      setAdminData(admin);
      setUpdatedAdminData(admin);
      setSelectedRole(admin.role_id); // Set the selected role
      //console.log(admin);
    } catch (error) {
      console.error(`Error fetching admin with ID ${adminId}:`, error);
    }
  };
  async function searchAdmins(e, page) {
    if (e.target.value) {
      try {
        const token = localStorage.getItem('userToken');
        const lang = 'en'; // Replace 'en' with the desired language value

        const response = await axios.get(
          `https://api.alaran.de/api/dashboard/tables_search/admins/${e.target.value}?page=${page}`,
          {
            headers: {
              token: token,
              lang: lang,
            },
          }
        );

        const { data } = response;
        setAdmins(data?.data?.admins);
        //console.log(data);
      } catch (error) {
        console.error('Error searching admins:', error);
      }
    } else {
      // Call the fetchAdmins function to reset the table
      fetchAdmins();
    }
  }




  useEffect(() => {
    fetchAdmins(currentPage);
    getRolesData()
  }, [currentPage]);

  const handleCreateAdmin = () => {
    setDisplayDialog(true);
  };
  const handleCloseDialog = () => {
    setDisplayDialog(false);
    setNewAdminData({
      name: '',
      email: '',
      role_id: [],
      password: ''
    });
  };


  const handleEdit = (adminId) => {
    //console.log('Edit button clicked');
    fetchAdminById(adminId);
    setSelectedAdminId(adminId);
    setDisplayEditDialog(true);
  };

  const handleDelete = async (adminId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this admin?');
    if (!confirmDelete) {
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      await axios.delete('https://api.alaran.de/api/dashboard/admins/delete', {
        headers: {
          token: token,
        },
        data: {
          admin_id: adminId,
        },
      }); // Replace with your backend API endpoint for deleting an admin
      fetchAdmins(currentPage);
      alert('Admin deleted successfully.');
    } catch (error) {
      console.error('Error deleting admin:', error);
      alert('Failed to delete admin.');
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    await fetchAdmins(page);
  };

  const handleSaveAdmin = async () => {
    try {
      const token = localStorage.getItem('userToken');

      const payload = {
        name: newAdminData.name,
        email: newAdminData.email,
        role_id: newAdminData.role_id.length > 0 ? newAdminData.role_id[0].id : null,
        password: newAdminData.password,
      };

      const response = await axios.post(
        'https://api.alaran.de/api/dashboard/admins/create',
        payload,
        {
          headers: {
            token: token,
          },
        }
      );

      // Handle the response as needed
      //console.log('Admin created:', response.data);
      alert('Admin Created Successfully');
      fetchAdmins();

      // Close the dialog and reset the form
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating admin:', error);
      // Handle the error condition
      alert('Failed to create admin.');
    }
  };
  const handleCancel = () => {
    setDisplayDialog(false);
    setNewAdminData({
      name: '',
      email: '',
      role_id: [], // Set as an empty array
      password: '',
    });
  };
  const handleUpdateAdminData = async () => {
    try {
      const payload = {
        ...updatedAdminData,
        role_id: selectedRole ? selectedRole : null,
      };

      const response = await axios.post(
        `https://api.alaran.de/api/dashboard/admins/update/${selectedAdminId}`,
        payload,
        {
          headers: {
            token: localStorage.getItem('userToken'),
          },
        }
      );

      fetchAdmins();
      alert('Updated Successfully');
      setDisplayEditDialog(false);

      // Handle the response from the backend as needed
    } catch (error) {
      console.error('Error updating admin data:', error);
      alert('Failed to update');
    }
  };
  const handleNameChange = (e) => {
    setUpdatedAdminData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleEmailChange = (e) => {
    setUpdatedAdminData((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  const handleRoleIdChange = (e) => {
    setUpdatedAdminData((prevState) => ({
      ...prevState,
      role_id: e.target.value,
    }));
  };

  const handlePasswordChange = (e) => {
    setUpdatedAdminData((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };
  const handleCloseEditDialog = () => {
    setDisplayEditDialog(false);
  };
  return (
    <div>

      <div className="flex mb-2 d-flex px-2  bg-light flex-wrap gap-2 align-items-center justify-content-between">
        <Button label={isLang === "en" ? "Add Admin" : "إضافة مدير"} className="p-button-success" icon="pi pi-plus" onClick={handleCreateAdmin} />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText onChange={searchAdmins} type="search" onInput={(e) => searchAdmins(e?.target?.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />

        </span>
      </div>


      <div>
        <table className="table pt-2">
          <thead className="table-light">
            <tr>
              <th scope="col">{isLang === "en" ? "Role ID" : "الرقم"}</th>
              <th scope="col">{isLang === "en" ? "Name" : "الاسم"}</th>
              <th scope="col">{isLang === "en" ? "Email" : "الايميل"}</th>
              <th scope="col">{isLang === "en" ? "Role" : "الوظيفة"}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {admins.map((admin) => (
              <tr className='role-tr' key={admin.id}>
                <td>{admin.id}</td>
                <td>{admin.name}</td>
                <td>{admin.email}</td>
                <td>{admin?.role?.name}</td>
                <td>
                  <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="mr-2"
                    onClick={() => handleEdit(admin.id)}
                  />

                  <Button icon="pi pi-trash"
                    rounded outlined severity="danger"
                    onClick={() => handleDelete(admin.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </button>
          <span>{currentPage}</span>
          <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </button>
        </nav>
      </div>
      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} header={isLang === "en" ? "Add Admin" : "إضافة مدير"} visible={displayDialog} onHide={handleCancel} style={{ width: '45rem', height: '450px' }}>
        <div className="p-grid">
          <div className="p-col- d-flex gap- mb-2">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Name" : "الاسم"}</label>
            <InputText className='w-100'
              value={newAdminData.name}
              onChange={(e) => setNewAdminData({ ...newAdminData, name: e.target.value })}
            />
          </div>
          <div className="p-col-4 d-flex gap-3 mb-2">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Email" : "الايميل"}</label>
            <InputText className='w-100'
              value={newAdminData.email}
              onChange={(e) => setNewAdminData({ ...newAdminData, email: e.target.value })}
            />
          </div>

          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Password" : "كلمة السر"}</label>
            <InputText className='w-100'
              value={newAdminData.password}
              onChange={(e) => setNewAdminData({ ...newAdminData, password: e.target.value })}
            />
          </div>
        </div>
        <div className="p-col-4 d-flex gap-3 mb-4">
          <label className="font-bold h5 d-flex w-25"> {isLang === "en" ? "Role" : "الوظيفة"}</label>

          <MultiSelect
            className="w-100"
            value={Array.isArray(newAdminData.role_id) ? newAdminData.role_id : [newAdminData.role_id]}
            options={roles}
            onChange={(e) => {
              setNewAdminData({ ...newAdminData, role_id: e.value });
            }}
            optionLabel="name"
            placeholder={isLang === "en" ? "Select Role" : "حدد الوظيفة"}
          />
        </div>
        <div className="p-dialog-footer text-center">
          <Button label={isLang === "en" ? "Cancel" : "إلغاء"} icon="pi pi-times" className="p-button-text" onClick={handleCancel} />
          <Button label={isLang === "en" ? "Save" : "حفظ"} icon="pi pi-check" className="p-button-success" onClick={handleSaveAdmin} />
        </div>
      </Dialog>
      <Dialog header="Edit Admin" visible={displayEditDialog} onHide={handleCloseEditDialog} style={{ width: '45rem', height: '450px' }}>
        <div className="p-grid">

          <div className="p-col- d-flex gap- mb-2">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Name" : "الاسم"}</label>

            <InputText
              className='w-100'
              label="Name"
              value={updatedAdminData.name || ''}
              onChange={handleNameChange}
            />

          </div>

          <div className="p-col- d-flex gap- mb-2">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Email" : "الايميل"}</label>

            <InputText
              className='w-100'
              label="Email"
              value={updatedAdminData.email || ''}
              onChange={handleEmailChange}
            />
          </div>

          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Password" : "كلمة السر"}</label>

            <InputText
              className='w-100'
              label="Password"
              value={updatedAdminData.password || ''}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25">{isLang === "en" ? "Role" : "الوظيفة "}</label>

            <select
              className="form-select w-100 form-select-lg mb-3" aria-label=".form-select-lg example"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

        </div>
        <DialogActions>
          <Button onClick={handleUpdateAdminData} color="primary">
            Save
          </Button>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminTable;