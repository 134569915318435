import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbook } from 'mdi-material-ui';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { VendersContext } from '../../context/Store';

const RoleTable = () => {
    let { isLang, setIsLang } = useContext(VendersContext);

    const [roles, setRoles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('langChange') || 'en');
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [permissions, setPermissions] = useState([]); // Initialize as an empty array
    const [newRoleName, setNewRoleName] = useState('');
    const [newNameAR, setNewNameAR] = useState('');
    const [newNameDE, setNewNameDE] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [editedRole, setEditedRole] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [editedName, setEditedName] = useState('');
    useEffect(() => {
        fetchRoles();
        fetchPermissions();

    }, [currentPage, selectedLanguage]);



    const fetchRoles = async () => {
        try {
            const response = await axios.get(
                `https://api.alaran.de/api/dashboard/roles?page=${currentPage}`,
                {
                    headers: {
                        token: localStorage.getItem('userToken'),
                        lang: selectedLanguage,
                    },
                }
            );
            const { roles: fetchedRoles, total } = response.data.data;
            setRoles(fetchedRoles);
            setTotalPages(Math.ceil(total / 10)); // Assuming 10 roles per page
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };




    const handleEdit = (roleId, roleName, permissions, name_de, name_ar) => {
        setSelectedRole(roleId);
        setNewRoleName(roleName);
        setSelectedPermissions(permissions);
        setNewNameDE(name_de || ''); // Set the value of name_de in the state, or an empty string if it's null or undefined
        setNewNameAR(name_ar || ''); // Set the value of name_ar in the state, or an empty string if it's null or undefined
    };

    const handleCancelEdit = () => {
        setSelectedRole(null);
        setNewRoleName('');
        setNewNameDE(''); // Reset the value of name_de in the state
        setNewNameAR(''); // Reset the value of name_ar in the state
    };

    const handleSaveEdit = async () => {
        try {
            const response = await axios.post(
                'https://api.alaran.de/api/dashboard/roles/update',
                {
                    id: selectedRole,
                    name_en: newRoleName,
                    name_de: newNameDE,
                    name_ar: newNameAR,
                    permissions: selectedPermissions,
                },
                {
                    headers: {
                        token: localStorage.getItem('userToken'),
                        lang: selectedLanguage,
                    },
                }
            );
            fetchRoles()
            // Handle the response as needed
            //console.log('Role update response:', response);

            // Reset the selected role, new role name, selected permissions, name_de, and name_ar
            alert('Updated Successfully');

            setSelectedRole(null);
            setNewRoleName('');
            setSelectedPermissions([]);
            setNewNameDE('');
            setNewNameAR('');
        } catch (error) {
            console.error('Error updating role:', error);
            alert('Failed to Update');

        }
    };

    async function searchRoles(e, page) {
        if (e.target.value) {
            try {
                const token = localStorage.getItem('userToken');
                const lang = 'en'; // Replace 'en' with the desired language value

                const response = await axios.get(
                    `https://api.alaran.de/api/dashboard/tables_search/roles/${e.target.value}?page=${page}`,
                    {
                        headers: {
                            token: token,
                            lang: lang,
                        },
                    }
                );

                const { data } = response;
                setRoles(data?.data?.roles);
                //console.log(data);
            } catch (error) {
                console.error('Error searching admins:', error);
            }
        } else {
            // Call the fetchAdmins function to reset the table
            fetchRoles();
        }
    }
    const handleDelete = async (roleId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this role?');
        if (confirmDelete) {
            try {
                await axios.delete(
                    'https://api.alaran.de/api/dashboard/roles/delete',
                    {
                        headers: {
                            token: localStorage.getItem('userToken'),
                            'Content-Type': 'application/json',
                        },
                        data: {
                            role_id: roleId,
                        },
                    }
                );
                alert('Deleted Successfully');
                fetchRoles();
            } catch (error) {
                console.error('Error deleting role:', error);
                alert('Failed to delete this role');
            }
        }
    };
    const getLocalizedRoleName = (role) => {
        if (role.translations && role.translations.length > 0) {
            const translation = role.translations.find(
                (translation) => translation.locale === selectedLanguage
            );
            if (translation && translation.name) {
                return translation.name;
            }
        }
        return role.name;
    };

    const showDialog = () => {
        setNewRoleName('');
        setSelectedPermissions([]);
        setSelectedRole(null);
        setDisplayDialog(true);
    };

    const hideDialog = () => {
        setDisplayDialog(false);
    };
    const fetchPermissions = async () => {
        try {
            const response = await axios.get('https://api.alaran.de/api/dashboard/roles/get_permissions', {
                headers: {
                    token: localStorage.getItem('userToken'),
                },
            });
            //console.log('Response:', response.data.data); // Check the response data structure
            const permissionsData = response.data.data;
            //console.log('Permissions Data:', permissionsData); // Check the permissionsData
            const permissionsArr = Object.keys(permissionsData).map((key) => ({
                id: key,
                name: permissionsData[key],
            }));
            //console.log('Permissions Array:', permissionsArr); // Check the permissionsArr
            setPermissions(permissionsArr);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };
    const handlePermissionChange = (event) => {
        const permissionId = event.target.value;
        if (event.target.checked) {
            setSelectedPermissions((prevPermissions) => [...prevPermissions, permissionId]);
        } else {
            setSelectedPermissions((prevPermissions) => prevPermissions.filter((p) => p !== permissionId));
        }
    };



    const createRole = async () => {
        try {
            const response = await axios.post(
                'https://api.alaran.de/api/dashboard/roles/create',
                {
                    name_en: newRoleName,
                    name_de: newNameDE,
                    name_ar: newNameAR,
                    permissions: selectedPermissions,
                },
                {
                    headers: {
                        token: localStorage.getItem('userToken'),
                    },
                }
            );
            const newRole = response.data.data;
            setRoles([...roles, newRole]);
            alert('Created Successfully');
            fetchRoles();
            hideDialog();
        } catch (error) {
            console.error('Error creating role:', error);
            alert('Failed to add this role');
        }
    };
    return (
        <>
            <div dir={isLang == "en" ? 'ltr' : 'rtl'} className="flex mb-2 d-flex px-2  bg-light flex-wrap gap-2 align-items-center justify-content-between">
                <Button label={isLang === "en" ? "Add Role" : "إضافة وظيفة"} icon="pi pi-plus" className="p-button-success" onClick={showDialog} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText onChange={searchRoles} type="search" onInput={(e) => searchRoles(e?.target?.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />

                </span>
            </div>
            <div dir={isLang == "en" ? 'ltr' : 'rtl'}>

                <table className="table pt-2">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">{isLang === "en" ? "Role ID" : "الرقم"}</th>
                            <th scope="col">{isLang === "en" ? "Role Name" : "اسم الوظيفة"}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {roles.map((role) => (
                            <tr className='role-tr' key={role.id}>
                                <td>{role.id}</td>
                                <td>{getLocalizedRoleName(role)}</td>
                                <td>
                                    <Button
                                        icon="pi pi-pencil"
                                        rounded
                                        outlined
                                        className="mr-2"
                                        onClick={() => handleEdit(role.id, role.name, role.permissions)}
                                    />
                                    <Button icon="pi pi-trash"
                                        rounded outlined severity="danger"
                                        onClick={() => handleDelete(role.id)} />
                                </td>
                            </tr>))}
                    </tbody>
                </table>

                <div>
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            disabled={index + 1 === currentPage}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
            {/* Dialog for creating a new role */}
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={displayDialog} onHide={hideDialog} header={isLang === "en" ? "Add Role" : "إضافة وظيفة"} style={{ width: '45rem', height: '550px' }}>

                <TabView>

                    <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"}>
                        <div className='d-flex gap-3'>
                            <label htmlFor="newRoleName" className="font-bold h5 d-flex w-25">
                                {isLang === "en" ? "EN_Name" : "الاسم:"}
                            </label>
                            <InputText
                                id="newRoleName"
                                value={newRoleName}
                                onChange={(e) => setNewRoleName(e.target.value)}
                                required
                                className='w-100'
                            />
                        </div>
                    </TabPanel>
                    <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"}>
                        <div className='d-flex gap-3'>
                            <label htmlFor="newNameDE" className="font-bold h5 d-flex w-25">
                                {isLang === "en" ? "DE_Name" : "الاسم:"}
                            </label>
                            <InputText
                                id="newNameDE"
                                value={newNameDE}
                                onChange={(e) => setNewNameDE(e.target.value)}
                                required
                                className='w-100'
                            />
                        </div>

                    </TabPanel>
                    <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"}>
                        <div className='d-flex gap-3'>
                            <label htmlFor="newNameAR" className="font-bold h5 d-flex w-25">
                                {isLang === "en" ? "AR_Name:" : "الاسم:"}
                            </label>
                            <InputText
                                id="newNameAR"
                                value={newNameAR}
                                onChange={(e) => setNewNameAR(e.target.value)}
                                required
                                className='w-100'
                            />
                        </div>

                    </TabPanel>
                </TabView>







                <div dir={isLang == "en" ? 'rtl' : 'ltr'} className='form-check'>
                    <h5 dir={isLang == "en" ? 'rtl' : 'rtl'} className='p-0 text-start mt-4'> {isLang === "en" ? "Select This Role Permissions" : "حدد صلاحيات الوظيفة"} </h5>
                    {permissions.map((permission) => (
                        <div className='mb-2' key={permission.id}>
                            <input
                                type="checkbox"
                                className="form-check-input checkbox-design"
                                value={permission.id}
                                checked={selectedPermissions.includes(permission.id)}
                                onChange={handlePermissionChange}
                                disabled={false}
                            />
                            <label className="form-check-label h6" htmlFor={permission.id}>{permission.id}</label>
                        </div>
                    ))}
                </div>
                <div className='text-center'>
                    <Button label={isLang === "en" ? "Cancel" : "إلغاء"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                    <Button label={isLang === "en" ? "Save" : "حفظ"} icon="pi pi-check" className="p-button-success" onClick={createRole} />
                </div>
            </Dialog >
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'}
                visible={selectedRole !== null}
                onHide={handleCancelEdit}
                header="Edit Role"
                style={{ width: '45rem', height: '550px' }}
                footer={
                    <div dir={isLang == "en" ? 'rtl' : 'rtl'}>
                        <div className='text-center'>
                            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleCancelEdit} />
                            <Button label="Save" icon="pi pi-check" className="p-button-success" onClick={handleSaveEdit} />
                        </div>
                    </div>
                }
            >
                <div className="edit-content">
                    <TabView>

                        <TabPanel header="English">
                            <div className='d-flex gap-3'>
                                <label htmlFor="newRoleName" className="font-bold h5 d-flex w-25">
                                    Role Name:
                                </label>
                                <InputText
                                    id="newRoleName"
                                    value={newRoleName}
                                    onChange={(e) => setNewRoleName(e.target.value)}
                                    required
                                    className='w-100'
                                />
                            </div>
                        </TabPanel>
                        <TabPanel header="German">

                            <div className='d-flex gap-3'>
                                <label htmlFor="newNameDE" className="font-bold h5 d-flex w-25">
                                    Name DE:
                                </label>
                                <InputText
                                    id="newNameDE"
                                    value={newNameDE}
                                    onChange={(e) => setNewNameDE(e.target.value)}
                                    required
                                    className='w-100'
                                />
                            </div>
                        </TabPanel>

                        <TabPanel header="Arabic">
                            <div className='d-flex gap-3'>
                                <label htmlFor="newNameAR" className="font-bold h5 d-flex w-25">
                                    Name AR:
                                </label>
                                <InputText
                                    id="newNameAR"
                                    value={newNameAR}
                                    onChange={(e) => setNewNameAR(e.target.value)}
                                    required
                                    className='w-100'
                                />
                            </div>
                        </TabPanel>

                    </TabView>


                    <div className='form-check'>
                        <h5 className='p-0 text-start mt-4'> Select This Role Permissions</h5>
                        {permissions.map(permission => (
                            <div key={permission.id}>
                                <input
                                    type="checkbox"
                                    className="form-check-input checkbox-design"
                                    id={`permission-${permission.id}`}
                                    value={permission.id}
                                    checked={selectedPermissions.includes(permission.id)}
                                    onChange={handlePermissionChange}
                                />
                                <label htmlFor={`permission-${permission.id}`}>{permission.id}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>
        </>

    );
};

export default RoleTable;