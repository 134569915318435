import { FiGrid } from 'react-icons/fi';
import { BiCategoryAlt, BiCartAlt, BiCog, BiDownload, BiStore, BiUser, BiBriefcase, BiCommentDetail, BiCommentError, BiCube, BiAlignMiddle } from 'react-icons/bi';
import { HiCubeTransparent } from 'react-icons/hi';
const allowedPages = JSON.parse(localStorage.getItem('allowedPages'));

const routes = [
  {
    path: "",
    nameEn: "Dashboard",
    nameAr: "لوحة القيادة",
    icon: <FiGrid className="logoSvg" style={{ width: 17 }} />,
  },


  // {
  //   path: "/test",
  //   nameEn: "test",
  //   nameAr: " الأقسام ",
  //   icon: <BiCategoryAlt className="logoSvg" style={{ width: 17 }} />,
  //   subRoutes: [
  //     {
  //       path: "/settings/about_us",
  //       nameAr: "من نحن",
  //       nameEn: "about_us",
  //       icon: <BiCategoryAlt className="logoSvg" style={{ width: 7 }} />,
  //     },
  //     {
  //       path: "/settings/our_clients",
  //       nameAr: " عملائنا  ",
  //       nameEn: "our_clients",
  //       icon: <BiCategoryAlt className="logoSvg" style={{ width: 7 }} />,
  //     }
  //   ]
  // },

  {
    path: "/Inventory",
    nameEn: "Inventory",
    nameAr: "قسم الجرد",
    icon: <HiCubeTransparent className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/brands",
        nameEn: "Brands",
        nameAr: " العلامات التجارية",
        icon: <HiCubeTransparent className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/products",
        nameEn: "Products",
        nameAr: " المنتجات ",
        icon: <BiCartAlt className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/stores",
        nameEn: "Stores",
        nameAr: "المتجر",
        icon: <BiStore className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/categories",
        nameEn: "Categories",
        nameAr: " الأقسام ",
        icon: <BiCategoryAlt className="logoSvg" style={{ width: 17 }} />,
      },

    ]
  },

  {
    path: "/suggested-products",
    nameEn: "Suggested Products",
    nameAr: " المنتجات المقترحة ",
    icon: <BiDownload className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/insects-keywords",
    nameEn: "Insects Keywords",
    nameAr: "  الكلمات المفتاحية الحشرات ",
    icon: <BiDownload className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/users",
    nameEn: "Users",
    nameAr: " العملاء ",
    icon: <BiUser className="logoSvg" style={{ width: 17 }} />,
  },

  {
    path: "/settings",
    nameEn: " Settings",
    nameAr: " الاعدادات ",
    icon: <BiCog className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/settings",
        nameEn: "General Settings",
        nameAr: "  الاعدادات العامة ",
        icon: <BiCog className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/ststic-page",
        nameEn: "Ststic Page",
        nameAr: " البيانات الأساسية ",
        icon: <BiCube className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/admins",
        nameEn: "Admins",
        nameAr: " المدراء ",
        icon: <BiBriefcase className="logoSvg" style={{ width: 17 }} />,
      },


    ]
  },
  {
    path: "/Reports",
    nameEn: " Reports",
    nameAr: " التقارير ",
    icon: <BiCog className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/reports-users",
        nameEn: "Users Report",
        nameAr: " تقرير العملاء ",
        icon: <BiAlignMiddle className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/reports-products",
        nameEn: "Products Report",
        nameAr: " تقرير المنتجات ",
        icon: <BiAlignMiddle className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/reports-scanned",
        nameEn: "Scanned Report",
        nameAr: " تقرير منتجات العملاء ",
        icon: <BiAlignMiddle className="logoSvg" style={{ width: 17 }} />,
      },
    ]
  },

  {
    path: "/complaints",
    nameEn: "Complaints",
    nameAr: " الشكاوى ",
    icon: <BiCommentError className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/reviews",
    nameEn: "Reviews",
    nameAr: " التعليقات ",
    icon: <BiCommentDetail className="logoSvg" style={{ width: 17 }} />,
  },


];

export default routes