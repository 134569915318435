import React, { useState, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import StaticPage from '../Inventory/StaticPage';
import Points from '../Inventory/Points';
import Pixels from '../Inventory/Pixels';
import CompanyInfo from './CompanyInfo';
import ComplaintsMessages from './ComplaintsMessages';
import { VendersContext } from '../../context/Store';

const SettingForm = () => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    image: '',
    phone: '',
    old_password: '',
    new_password: '',
  });


  const [notificationData, setNotificationData] = useState({
    title: '',
    body: ''
  });

  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNotificationData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleNotificationSend = async (e) => {
    e.preventDefault();

    const requestData = {
      registration_ids: [],
      title: notificationData.title,
      body: notificationData.body
    };

    try {
      const response = await fetch('https://api.alaran.de/api/dashboard/notifications/push_noitification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('userToken') // Add the token header

        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        alert('Notification sent successfully!');
      } else {
        alert('Failed to send notification.');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
    //console.log(requestData);

  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
  }, []);


  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };



  // const fetchProfileData = async () => {
  //   try {
  //     const response = await fetch('https://api.alaran.de/api/dashboard/profile/get_profile', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         token: localStorage.getItem('userToken') // Add the token header
  //       }
  //     });
  //     const data = await response.json();
  //     //console.log(data.data)
  //     setFormData(data.data);
  //   } catch (error) {
  //     console.error('Error fetching profile data:', error);
  //   }
  // };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleCompanyInfo = async (e) => {
    e.preventDefault();
    //console.log(formData);
    try {
      // Create a new FormData object to send the updated data
      const formDataWithImage = new FormData();
      formDataWithImage.append('image', selectedImage);
      formDataWithImage.append('name', formData.name);
      formDataWithImage.append('email', formData.email);

      // Send the updated data to the server
      const response = await fetch('https://api.alaran.de/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('userToken') // Add the token header
        },
        body: formDataWithImage
      });

      if (response.ok) {
        setIsUpdateSuccessful(true);
        // Fetch the updated data and update the form
        alert('Profile updated successfully!');
      } else {
        setIsUpdateSuccessful(false);
        alert('Failed to update profile.');
      }
    } catch (error) {
      setIsUpdateSuccessful(false);
      console.error('Error updating profile:', error);
    }
  };
  const handlePasswordChange = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        'https://api.alaran.de/api/dashboard/profile/change_password', // Update the URL with the correct endpoint
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('userToken') // Add the token header
          },
          body: JSON.stringify({
            id: formData.id,
            old_password: formData.old_password,
            new_password: formData.new_password
          })
        }
      );
      if (response.ok) {
        alert('Password changed successfully!');
      } else {
        alert('Failed to change password.');
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (

    <TabView className='card rounded-4 p-4'>
      <TabPanel header={isLang === "en" ? "Company Information" : "بيانات الشركة"}>

        <div>

          <CompanyInfo />
          {/* <h2>Change Password</h2> */}

        </div>
      </TabPanel>

      <TabPanel header={isLang === "en" ? "Notifications" : " الاشعارات"}>
        <form onSubmit={handleNotificationSend}>
          <div>
            <label htmlFor="title" className='h6 mt-3'>{isLang === "en" ? "Title:" : "عنوان الرسالة"} </label>
            <input className="form-control form-control-lg"
              type="text"
              id="title"
              name="title"
              value={notificationData.title}
              onChange={handleNotificationChange}
            />
          </div>
          <div>
            <label htmlFor="body" className='h6 mt-3'>{isLang === "en" ? "Body:" : "محتوى الرسالة"}</label>
            <textarea
              className="form-control form-control-lg mb-3"
              id="body"
              name="body"
              value={notificationData.body}
              onChange={handleNotificationChange}
            />
          </div>
          <button type="submit" className="btn btn-primary">{isLang === "en" ? "Send Notification:" : " ارسال الاشعار"}</button>

        </form>


      </TabPanel>
      {/* <TabPanel header="Static Page" >
        <StaticPage />
      </TabPanel> */}
      <TabPanel header={isLang === "en" ? "Points" : " النقاط"} > <Points /> </TabPanel>
      <TabPanel header={isLang === "en" ? "Pixels" : " الاعلانات"}  > <Pixels /> </TabPanel>
      <TabPanel header={isLang === "en" ? "Complaints" : " الشكاوى"} > <ComplaintsMessages /> </TabPanel>
      <TabPanel header={isLang === "en" ? "Payment" : " الدفع"} > </TabPanel>
      <TabPanel header={isLang === "en" ? "Subscriptions" : " الاشتراكات"} > </TabPanel>
    </TabView>

  );
};

export default SettingForm;