import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { TabView, TabPanel } from 'primereact/tabview';
import { VendersContext } from '../../context/Store';


const Categories = (props) => {


   
    let { isLang, setIsLang } = useContext(VendersContext);


    const [products, setProducts] = useState([]);

    async function getCategories(page) {
        try {
            const headers = {
                token: localStorage.getItem('userToken')
            };

            const response = await axios.get(`https://api.alaran.de/api/dashboard/categories?page=${page}`, { headers });
            const data = response.data;

            setProducts(data.data.categories);
            setTotalRecords(data.data.total);
        } catch (error) {
            console.error(error);
        }
    }



    async function searchCategories(e, page) {
        if (e.target.value) {

            let { data } = await axios.get(`https://api.alaran.de/api/dashboard/tables_search/categories/${e.target.value}?page=${page}`, {
                headers: {
                    token: localStorage.getItem('userToken')


                }
            })
            setProducts(data.data.categories);
            //console.log(data);
        }
        else {
            getCategories();
        }

    }

    useEffect(() => {


        getCategories(1);
    }, []);




    let emptyProduct = {
        name_en: '',
        name_ar: '',
        name_de: ''

    };


    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [nameTouched, setNameTouched] = useState(false);
    const toast = useRef(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const dtt = useRef(null);
    const [isAddingBrand, setIsAddingBrand] = useState(false);

    const [isEditDialog, setIsEditDialog] = useState(false); // Initially set to false for "Add" mode


    const openNew = () => {
        setIsAddingBrand(true);
        setProduct(emptyProduct);
        setProductDialog(true);
    };


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const onPageChange = (event) => {
        const newPage = event.page + 1; // Assuming the API pagination starts from 1
        setCurrentPage(newPage);
        getCategories(newPage);
        setFirst(event.first);
        setRows(event.rows);
    };


    const onSave = async (product) => {
        try {
            const response = await axios.post(`https://api.alaran.de/api/dashboard/categories/update`, product, {
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem('userToken')
                }
            });
            const data = response.data;
            // handle response data
            getCategories(currentPage);
            setProductDialog(false); // close dialog
            // Show a success toast message
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Category updated successfully', life: 3000 });
        } catch (error) {
            // handle error
            //console.log(error);
            // Show an error toast message
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update category', life: 3000 });
        }
    };
    const editProduct = (product) => {
        setProduct({
            id: product.id,
            name_en: product.name_en,
            name_ar: product.name_ar,
            name_de: product.name_de
        });
        setIsAddingBrand(false);
        setProductDialog(true);
    };


    //delete one product
    const hideDeleteBrandDialoge = () => {
        setDeleteProductsDialog(false);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        try {
            // Send a DELETE request to the API endpoint with the product ID to be deleted and the Authorization header with the token value
            await axios.delete(`https://api.alaran.de/api/dashboard/categories/delete/${product.id}`, {
                headers: {
                    token: localStorage.getItem('userToken')
                }
            });

            // Update the products list state to remove the product that was deleted
            const updatedProducts = products.filter((val) => val.id !== product.id);
            setProducts(updatedProducts);

            // Reset the product state
            setProduct(emptyProduct);

            // Hide the delete product dialog
            setDeleteProductDialog(false);

            // Show a success toast message
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Deleted', life: 3000 });
        } catch (error) {
            //console.log(error.response);
            // Show an error toast message
            setDeleteProductDialog(false);

            toast.current.show({ severity: 'error', summary: 'Error', detail: 'This Category is related to some products', life: 4000 });

        }
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };
    //delete selected product

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = async () => {
        try {
            // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
            const deletePromises = selectedProducts.map((product) => {
                return axios.delete(`https://api.alaran.de/api/dashboard/categories/delete/${product.id}`, {
                    headers: {
                        token: localStorage.getItem('userToken')
                    }
                });
            });
            await Promise.all(deletePromises);

            // Update the products list state to remove the deleted products
            const updatedProducts = products.filter((val) => !selectedProducts.includes(val));
            setProducts(updatedProducts);

            // Reset the selected products state and hide the delete products dialog
            setSelectedProducts(null);
            setDeleteProductsDialog(false);

            // Show a success toast message
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Categories Deleted', life: 3000 });
        } catch (error) {
            //console.log(error);
            // Show an error toast message
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete CAtegories', life: 3000 });
        }
    };




    const onInputChange = (e, name_en) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };

        _product[`${name_en}`] = val;

        setProduct(_product);

        if (name_en === 'name_en') {
            setNameTouched(true);
        }
    };



    const actionBodyTemplate = (rowData) => {
        return (
            <div className='d-flex gap-4'>
                <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="mr-2"
                    onClick={() => editProduct({
                        id: rowData.id,
                        name_en: rowData.name_en,
                        name_ar: rowData.name_ar,
                        name_de: rowData.name_de
                    })}
                />                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> {isLang === "en" ? "Manage Categories" : "إدارة الأقسام"}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText onChange={searchCategories} type="search" onInput={(e) => searchCategories(e.target.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />
            </span>
        </div>
    );
    const leftToolbarTemplate = () => {
        return (
            <div className="d-flex flex-wrap gap-3">
                <Button label={isLang === "en" ? "Add Category" : "اضافة قسم"} icon="pi pi-plus" severity="success" onClick={openNew} />        <Button
                    label={isLang === "en" ? "Delete" : "حذف"}
                    icon="pi pi-trash"
                    severity="danger"
                    onClick={confirmDeleteSelected}
                    className={selectedProducts && selectedProducts.length ? '' : 'p-disabled'}
                />
            </div>
        );
    };

    const addCategory = async (category) => {
        try {
            const response = await fetch('https://api.alaran.de/api/dashboard/categories/create', {
                method: 'POST',
                headers: {
                    token: localStorage.getItem('userToken'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(category),
            });
            const data = await response.json();

            // Fetch the updated categories
            setProducts([data, ...products]);
            getCategories();

            // Update the state with the new category

            // Show a success toast message
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Category added successfully', life: 3000 });

            // ... rest of the code ...
        } catch (error) {
            // handle error
            //console.log(error);
            // Show an error toast message
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add category', life: 3000 });
        }
    };
    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const handleSaveClick = () => {
        if (isAddingBrand) {
            addCategory(product);
        } else {
            onSave(product);
        }
        setIsAddingBrand(false);
        setProduct(emptyProduct);
        setProductDialog(false);
    };
    const productDialogFooter = (
        <div>
            <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={handleSaveClick} />
            <Button label={isLang === "en" ? "Cancel" : "إلغاء "} icon="pi pi-times" onClick={hideDialog} className="p-button-secondary" />
        </div>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
            <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
            <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    return (

        <div>
            <Toast ref={toast} />
            <div className="card ">
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                <DataTable value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
                    <Column selectionMode="multiple" className='px-3' exportable={true}></Column>
                    <Column field="id" header={isLang === "en" ? "ID" : "الرقم"} style={{ minWidth: '12rem' }} ></Column>
                    <Column field="name_en" header={isLang === "en" ? "Brand Name" : "القسم "} style={{ minWidth: '16rem' }} ></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
                <Paginator ref={dtt.current} first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} currentPage={currentPage - 1} />
            </div>


            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={productDialog} style={{ width: '34rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Add Category" : "اضافة قسم"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                <TabView>
                    <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"}>
                        <label htmlFor="name_en" className="font-bold">
                            {isLang === "en" ? "EN_Name" : "الاسم"}
                        </label>
                        <InputText
                            id="name_en"
                            value={product.name_en}
                            onChange={(e) => onInputChange(e, 'name_en')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.name_en })}
                        />
                        {submitted && !product.name_en && (
                            <small className="p-error">Name_EN is required.</small>
                        )}
                    </TabPanel>
                    <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"}>
                        <label htmlFor="name_ar" className="font-bold">
                            {isLang === "en" ? "AR_Name" : " الاسم"}
                        </label>
                        <InputText
                            id="name_ar"
                            value={product.name_ar}
                            onChange={(e) => onInputChange(e, 'name_ar')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.name_ar })}
                        />
                        {submitted && !product.name_ar && (
                            <small className="p-error">Name_AR is required.</small>
                        )}
                    </TabPanel>
                    <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"}>
                        <label htmlFor="name_de" className="font-bold">
                            {isLang === "en" ? "DE_Name" : "الاسم "}
                        </label>
                        <InputText
                            id="name_de"
                            value={product.name_de}
                            onChange={(e) => onInputChange(e, 'name_de')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.name_de })}
                        />
                        {submitted && !product.name_de && (
                            <small className="p-error">Name_DE is required.</small>
                        )}
                    </TabPanel>
                </TabView>

            </Dialog>



            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Confirm" : "تأكيد"} modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content ">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            {isLang === "en" ? "Are you sure you want to delete" : " هل أنت متأكد أنك تريد حذف"}  <b>{product.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Confirm" : "تأكيد"} modal footer={deleteProductsDialogFooter} onHide={hideDeleteBrandDialoge}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span> {isLang === "en" ? "Are you sure you want to delete the selected products?" : "   هل أنت متأكد أنك تريد حذف المنتجات المحددة؟"}</span>}
                </div>
            </Dialog>
        </div>


    );
}

export default (Categories);