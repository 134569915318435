import React, { useState, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { VendersContext } from '../../context/Store';

const StaticPage = () => {
    let { isLang, setIsLang } = useContext(VendersContext);

    const [settings, setSettings] = useState({
        privacy_policy_de: '',
        privacy_policy_en: '',
        privacy_policy_ar: '',
        about_us_de: '',
        about_us_en: '',
        about_us_ar: '',
        terms_conditions_de: '',
        terms_conditions_en: '',
        terms_conditions_ar: '',
        faq_text_de: '',
        faq_text_en: '',
        faq_text_ar: '',
    });

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await fetch('https://api.alaran.de/api/dashboard/setting/get_setting_values', {
                headers: {
                    token: localStorage.getItem('userToken'),
                },
            });
            const data = await response.json();
            setSettings(data.data);
            //console.log(data.data)
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.alaran.de/api/dashboard/setting/update_setting_info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem('userToken'),
                },
                body: JSON.stringify(settings),
            });
            if (response.ok) {
                //console.log('Settings updated successfully');
                alert('Updated successfully!');

            } else {
                console.error('Failed to update settings');
                alert('Failed to Update');

            }
            fetchSettings()
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='card rounded-4 p-4' >
            <TabView >

                <TabPanel header={isLang === "en" ? "Privacy Policy" : " سياسة الخصوصية"}>

                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "Privacy Policy (English)" : "سياسة الخصوصية (اللغة العربية)  "}</label>
                    <textarea
                        className="form-control form-control-lg "
                        rows="3"
                        id="privacy_policy_en"
                        name="privacy_policy_en"
                        value={settings.privacy_policy_en}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "Privacy Policy (Germany)" : "سياسة الخصوصية (اللغة الالمانية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="privacy_policy_de"
                        name="privacy_policy_de"
                        value={settings.privacy_policy_de}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "Privacy Policy (Arabic)" : "سياسة الخصوصية (اللغة العربية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="privacy_policy_ar"
                        name="privacy_policy_ar"
                        value={settings.privacy_policy_ar}
                        onChange={handleInputChange}
                    />
                </TabPanel>
                <TabPanel header={isLang === "en" ? "About Us" : " معلومات عنا"}>

                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "About Us (English)" : "معلومات عنا (اللغة الانجليزية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="about_us_en"
                        name="about_us_en"
                        value={settings.about_us_en}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "About Us (Germany)" : "معلومات عنا (اللغة الالمانية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="about_us_de"
                        name="about_us_de"
                        value={settings.about_us_de}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="privacy_policy_en" className='h6 mt-3'> {isLang === "en" ? "About Us (Arabic)" : "معلومات عنا (اللغة العربية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="about_us_ar"
                        name="about_us_ar"
                        value={settings.about_us_ar}
                        onChange={handleInputChange}
                    />
                </TabPanel>
                <TabPanel header={isLang === "en" ? "Terms & Conditions" : "  القواعد والشروط"}>

                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Terms & Conditions (English)" : "القواعد والشروط (اللغة الانجليزية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="terms_conditions_en"
                        name="terms_conditions_en"
                        value={settings.terms_conditions_en}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Terms & Conditions (Germany)" : "القواعد والشروط (اللغة الالمانية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="terms_conditions_de"
                        name="terms_conditions_de"
                        value={settings.terms_conditions_de}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Terms & Conditions (Arabic)" : "القواعد والشروط (اللغة العربية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="terms_conditions_ar"
                        name="terms_conditions_ar"
                        value={settings.terms_conditions_ar}
                        onChange={handleInputChange}
                    />

                </TabPanel>

                <TabPanel header={isLang === "en" ? "Faq" : " التعليمات "}>

                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Faq (English)" : " التعليمات (اللغة الانجليزية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="faq_text_en"
                        name="faq_text_en"
                        value={settings.faq_text_en}
                        onChange={handleInputChange}
                    />


                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Faq (Germany)" : " التعليمات (اللغة الالمانية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="faq_text_de"
                        name="faq_text_de"
                        value={settings.faq_text_de}
                        onChange={handleInputChange}
                    />




                    <label htmlFor="terms_conditions_en" className='h6 mt-3'>{isLang === "en" ? "Faq (Arabic)" : " التعليمات (اللغة العربية)  "}</label>
                    <textarea rows="3"


                        className="form-control form-control-lg"
                        id="faq_text_ar"
                        name="faq_text_ar"
                        value={settings.faq_text_ar}
                        onChange={handleInputChange}
                    />
                </TabPanel>
            </TabView>
            <button type="submit" className="btn btn-primary static-btn " >{isLang === "en" ? "Save" : "حفظ"}</button>



        </form>
    );
};

export default StaticPage;